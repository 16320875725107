import React, { useRef } from 'react';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';

const VideoPlayer = (props) => {
    const playerRef = useRef();

    return (
        <Player
            autoPlay
            playsInline
            poster={props.thumbnail}
            ref={playerRef}
            videoId="video-1"
            className='!pt-[80vh]'
            src={props.url}
        >
            <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton disabled />
            </ControlBar>
        </Player>

    );

}

export default VideoPlayer;