import React from 'react'
import ThemeButton from '../../components/buttons/themeButton'

const EarningHeader = ({openUpdateBankModal, openWithdrawlModal, payoutLoading, downloadToCSV}) => {
    return (
        <div className={"w-full flex justify-end mb-4 gap-2"}>
            <ThemeButton name={"Update Bank"} onClick={openUpdateBankModal}/>
            <ThemeButton
                disabled={payoutLoading}
                name={payoutLoading ? 'Loading...' : 'Withdraw'} onClick={openWithdrawlModal}
            />
            <ThemeButton name={"Download CSV"} onClick={downloadToCSV}/>
        </div>
    )
}

export default EarningHeader
