import React, { useState } from 'react';
import PageHeading from "../../../components/pageHeading/PageHeading";
import Button from '../../../components/buttons/Button';
import DefaultInput from '../../../components/inputs/DefaultInput';
import { updatePassword } from '../../../../../service/service.user';
import toast from 'react-hot-toast';

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setError(''); // Reset error message on input change
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Clear previous errors
        setError('');

        if (formData.newPassword !== formData.confirmPassword) {
            setError('New Password and Confirm Password do not match');
            return;
        }

        // Proceed with password change logic here
        updatePassword(formData)
            .then((res) => {
                toast.success("Password changed successfully.");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            })
            .catch((err) => {
                // Assuming `err` contains the error response from the API
                if (err.response && err.response.data && err.response.data.error) {
                    const apiErrors = err.response.data.error;
                    let errorMessage = [];

                    if (apiErrors.oldPassword) {
                        errorMessage.push(apiErrors.oldPassword.join(', ') + '.'); // Add a full stop
                    }
                    if (apiErrors.newPassword) {
                        errorMessage.push(apiErrors.newPassword.join(', ') + '.'); // Add a full stop
                    }
                    if (apiErrors.confirmPassword) {
                        errorMessage.push(apiErrors.confirmPassword.join(', ') + '.'); // Add a full stop
                    }

                    setError(errorMessage.join('<br />')); // Join with <br /> for line breaks
                } else {
                    setError('An unexpected error occurred.');
                }
            })
            .finally(() => { });

    };


    return (
        <div>
            <PageHeading
                heading={"Change Password"}
                profile
            />
            <div className="p-4 h-[calc(100vh-70px)] overflow-y-auto">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 max-w-md">
                    <DefaultInput
                        onChange={handleChange}
                        name="oldPassword"
                        value={formData.oldPassword}
                        label={"Old Password"}
                        placeholder={"Enter Old Password"}
                        bg={"bg-theme-3"}
                        type="password"
                        required
                    />

                    <DefaultInput
                        onChange={handleChange}
                        name="newPassword"
                        value={formData.newPassword}
                        label={"New Password"}
                        placeholder={"Enter New Password"}
                        bg={"bg-theme-3"}
                        type="password"
                        required
                    />

                    <DefaultInput
                        onChange={handleChange}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        label={"Confirm New Password"}
                        placeholder={"Confirm New Password"}
                        bg={"bg-theme-3"}
                        type="password"
                        required
                    />

                    {error && (
                        <div className="text-red text-sm" dangerouslySetInnerHTML={{ __html: error }} />
                    )}
                    <div className='mt-5'>
                        <Button /* onClick={() => onSubmit()} */ name={'Change Password'} />
                    </div>

                    {/* <button
                        type="submit"
                        className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Change Password
                    </button> */}
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
