import {Menu, Transition} from '@headlessui/react'
import {Fragment, useEffect, useRef, useState} from 'react'
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";

export default function DropdownButton({items, title, icon, bg, dropdownArrow}) {
    return (
        <div className="text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        className={`
                        flex items-center gap-1 w-full justify-center rounded-md py-2 text-sm font-medium text-white 
                        focus:outline-none focus-visible:ring-2 
                        focus-visible:ring-white/75 ${bg ? "bg-theme-peach-200  px-4 hover:bg-theme-peach-100 " : " px-0"}
                        `}>
                        {icon && <span className={"text-[18px]"}>{icon}</span>}
                        {title}
                        {dropdownArrow ? <IoIosArrowDown/> : ""}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-[5px]
                         bg-black shadow-lg ring-1 ring-black/5 focus:outline-none text-[14px] font-medium">
                        <div className="px-1 py-1 ">
                            {items?.map((item, index) => {
                                return (
                                    <Menu.Item key={index} className={"text-white"}>
                                        {({active}) => (
                                            <button
                                                className={`${
                                                    active ? 'bg-neutral text-white' : 'text-white'
                                                } group flex w-full items-center rounded-[5px] px-2 py-2 text-sm text-white hover:bg-neutral`}
                                                onClick={item.onClick}
                                            >
                                                {item.name}
                                            </button>
                                        )}
                                    </Menu.Item>
                                )
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
