import React from 'react'

const ValidateInput = ({ label, type, register, placeholder, error, bg, icon, name, value, className, onChange = () => null }) => {
    return (
        <div className={"relative"}>
            {label && <label className={"text-white text-[14px] font-medium"}>{label}</label>}
            {icon &&
                <div className="absolute h-10 bg-black/20 bottom-0 left-0 w-10 rounded-[2px]
         justify-center items-center flex text-[20px] text-white dark:text-black">
                    {icon}
                </div>
            }
            <input
                className={`w-full h-10 mt-1 rounded-[5px] focus:outline-none ${className ? className : "text-white"}  text-[14px]
        ${bg ? bg : "bg-white "} ${icon ? "pl-12 pr-2" : "pl-2 pr-2"} ${error ? "border-[1px] border-[solid] border-[red]" : ""}
       `}
                type={type}
                // value={value}
                {...register(name, { required: true })}
                placeholder={placeholder}
            />
            {error && <span className="absolute top-[70px] right-[0] text-[red] text-[11px] italic">This field is required</span>}
        </div>
    )
}

export default ValidateInput



