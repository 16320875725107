import React, { useEffect, useState } from 'react';
import DefaultDropdown from "../../components/buttons/defalutDropdown";
import { Link } from 'react-router-dom';
import { MAIN_DOMAIN } from '../../../../constants/constants';
import { IoIosFolder } from "react-icons/io";

const Folders = ({
    item, deleteFile, openShareModal, openEditModal, openSaleDetailModal = () => {
    }, setSearchText, downloadFolder = () => null
}) => {
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        let menuItems = [];
        if (item.folder?.ownership === 'owner') {
            menuItems = [{
                label: 'Share',
                onClick: openShareModal
            },
            {
                label: 'Edit',
                onClick: openEditModal
            },
            {
                label: 'Delete',
                onClick: deleteFile
            }];
        }

        menuItems.push({
            label: 'Download',
            onClick: downloadFolder
        });

        if (item.folder?.type === 1 && (item.folder?.ownership === 'shared_view' && item.meta?.share)) {
            menuItems.push({
                label: 'Purchase',
                // onClick: () => window.open(`https://cloud.${MAIN_DOMAIN}/shared/folder/${item.meta.share.uuid}`)
                onClick: () => window.open(`/shared/folder/${item.meta.share.uuid}`)
            });
        }

        if (item.folder?.type > 0 && (item.folder?.ownership === 'owner' || item.folder?.ownership === 'purchased')) {
            menuItems.push({
                label: 'Sale Detail',
                onClick: openSaleDetailModal
            });
        }

        setMenu(menuItems);
    }, [item]);
    return (
        <div className={"border border-theme-5 rounded-md flex justify-between items-center text-white text-sm h-[70px]"}>
            <Link
                onClick={() => setSearchText("")}
                to={`/cloud/drive/${item.id}`}
                className='w-full flex items-center relative'
            >
                <div
                    className={`flex items-center flex-1 cursor-pointer pl-2 py-2 gap-2 truncate w-[150px] md:w-[200px]
                    font-inter font-medium`}>
                    <span
                        className={`text-3xl text-white/60`}>
                        <IoIosFolder />
                        {/*{item.meta?.svg}*/}
                    </span>
                    <div className={"flex flex-col"}>
                        <span>{item?.folder?.name || item.meta?.name}</span>
                        <div className={"flex gap-1 text-gray-400 items-center"}>
                            <div className={`w-2 h-2 rounded-full ${item.folder?.ownership === 'owner' ? "bg-red" : item.folder?.ownership === 'shared_view' ? "bg-green-primary" : "bg-yellow"}`} />
                            <span className={` ${item.folder?.ownership === 'owner' ? "text-red" : item.folder?.ownership === 'shared_view' ? "text-green-primary" : "text-yellow"}`}>
                                {/* {item.folder?.ownership === 'owner' ? "Owner" : item.folder?.ownership === 'shared_view' ? "Shared" : "Purchased"} */}
                                {item.folder?.ownership === 'owner' ? (
                                    "Owner"
                                ) : item.folder?.ownership === 'shared_view' ? (
                                    "Shared"
                                ) : (
                                    <span className='flex items-center gap-2'>
                                        <span>Purchased</span>
                                        <span>
                                             {item.folder?.ownership === 'purchased' ? (
                                            <>

                                                <p className='text-white'>({item.folder?.user?.name}) </p>
                                            </>
                                        ) : ""}</span>
                                    </span>
                                )}
                            </span>

                        </div>

                    </div>

                </div>
            </Link>

            {item.meta?.canDelete === 0 || menu.length <= 0 ? <span className="px-4" /> :
                <button className={`flex flex-0 items-center h-10 px-4`}>
                    <DefaultDropdown data={item} content={menu} />
                </button>
            }
        </div>
    );
};

export default Folders;
