import Axios from "../configs/axios.config";
export const getContents = (params) => Axios.get(`/content/home`, { params });
export const getRelatedContents = (uuid) => Axios.get(`/content/related/${uuid}`);
export const getSearchContents = (params) => Axios.get(`/content/search`, { params: params });
export const getContentById = (id) => Axios.get(`/content/${id}`);
export const deleteContent = (uuid) => Axios.delete(`/content/${uuid}`);
export const listArchiveContent = () => Axios.get(`/get-archived-content`);
export const archiveContent = (uuid) => Axios.post(`/archive/content/${uuid}`);
export const restoreContent = (uuid) => Axios.post(`/restore/content/${uuid}`);
export const getUserContent = (id, queryParams = null) => Axios.get(`/content/get/${id}`, { params: queryParams });
export const getPurchasedContent = () => Axios.get(`/profile/content/purchased`);
export const getLikedContent = (params) => Axios.get(`/liked/content`, { params });
export const getDownloadedContent = (params) => Axios.get(`/downloaded/content`, { params });
export const updateContent = (id, content) => Axios.put(`/content/${id}`, content);
export const submitContent = (content) => Axios.post(`/content/submit`, content);
export const likeContent = (id) => Axios.post("/content/like", { content_id: id });
export const unlikeContent = (id) => Axios.delete(`/content/like/${id}`);
export const requestDownload = (id, type = 1) => Axios.get(`/content/download/${id}?q=${type}`);
export const requestFolderDownload = (id, type = 1) => Axios.get(`/folder/download/${id}/${type}`);
export const autocompleteTag = (term) => Axios.get(`/autocomplete/tag/${term}`);

export const submitCategories = (content) => Axios.post(`/category/assign`, content);

