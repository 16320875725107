import React from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useState} from 'react'
import UploadInput from '../../upload/components/UploadInput'
import {IoClose} from 'react-icons/io5'

const UploadModal = ({isOpen, closeModal, handleFileUpload}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/70"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-xl transform overflow-hidden rounded-[10px] bg-theme-3 p-6
                                 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white mb-5 pb-3"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span>Upload Files</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal}/>
                                    </div>
                                </Dialog.Title>
                                <div>
                                    <UploadInput handleFileUpload={handleFileUpload}/>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default UploadModal
