import React from 'react'
import Button from '../buttons/Button'

const LoadMore = ({ fetchNext, pageLoading, handleClick }) => {
    return (
        <div>
            {fetchNext ? (
                <div className="flex justify-center item-center">
                    {pageLoading ? (
                        <div className="flex flex-col justify-center items-center animate-[fadeIn_0.2s]">
                            <div
                                class={"inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-white"}
                                role="status">
                                <span
                                    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div>
                        </div>

                    ) : (
                        <Button name={"Load More"} onClick={handleClick} />
                    )}
                </div>
            ) : null}
        </div>

    )
}

export default LoadMore