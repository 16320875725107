import {Dialog} from "@headlessui/react";

export default function MenuDrawer({title, description, children, isOpen, setIsOpen}) {
    return (
        <Dialog
            unmount={false}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed z-30 inset-0 right-0 overflow-y-auto"
        >
            <div className="flex w-full h-screen">
                <Dialog.Overlay
                    className="z-40 fixed -inset-0 bg-black bg-opacity-30"
                />
                <div className={`z-50 flex flex-col justify-between bg-theme-1 w-full
                         max-w-sm p-0 overflow-y-auto text-left align-middle
                         shadow-xl`}>
                    <div>
                        {children}
                    </div>
                    {/*<div className="self-center mt-10">*/}
                    {/*    <button onClick={() => setIsOpen(!isOpen)}>Close</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Dialog>
    );
}
