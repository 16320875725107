import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import ButtonInput from "../inputs/ButtonInput";
import {IoClose} from 'react-icons/io5';
import DefaultInput from '../inputs/DefaultInput';
import {getBankDetails, updateBankDetails} from '../../../../service/service.user';
import toast from '../../../../utils/toast'


export default function AddBankModal({isOpen, modalRef, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [details, setDetails] = useState({
        account_number: '',
        name: '',
        bank_name: '',
        sort_code: '',
        swift_code: '',
        additional: '',
    });
    const [detailsError, setDetailsError] = useState({});

    const loadBankDetails = async () => {
        setLoading(true);
        await getBankDetails().then((res) => {
            if (res.data.details) {
                setDetails(res.data.details);
            }
        })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadBankDetails();
    }, []);

    const handleChange = (name, value) => {
        console.log("===", name, value)
        setDetails({...details, [name]: value})
    }


    const submitDetails = () => {
        setSaving(true);
        updateBankDetails(details)
            .then((res) => {
                toast.success(res.data.message);
                closeModal()
            })
            .catch((err) => setDetailsError(err.response.data.error))
            .finally(() => setSaving(false));
    }

    const getError = (key) => {
        if (Object.keys(detailsError).length === 0) {
            return '';
        }
        return detailsError.hasOwnProperty(key) ? detailsError[key][0] : '';
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" ref={modalRef} onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-3
                            p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3"
                                              className="text-lg font-medium leading-6 text-white mb-5"
                                >
                                    <div className='flex justify-between items-center'>
                                        <p>Bank Details</p>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal}/>
                                    </div>
                                </Dialog.Title>

                                <div className="mt-2 flex flex-col gap-2">
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-5"}
                                        value={details.name}
                                        name="name"
                                        onChange={handleChange}
                                        label={"Beneficiary Name"}
                                        placeholder={"Beneficiary Name"}
                                    />
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-5"}
                                        value={details.account_number}
                                        name="account_number"
                                        onChange={handleChange}
                                        label={"Account Number"}
                                        placeholder={"Account Number"}
                                    />
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-5"}
                                        value={details.bank_name}
                                        name="bank_name"
                                        onChange={handleChange}
                                        label={"Bank Name"}
                                        placeholder={"Bank Name"}
                                    />
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-5"}
                                        value={details.sort_code}
                                        name="sort_code"
                                        onChange={handleChange}
                                        label={"Sort Code"}
                                        placeholder={"Sort Code"}
                                    />
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-5"}
                                        value={details.additional}
                                        name="additional"
                                        onChange={handleChange}
                                        label={"Additional Details"}
                                        placeholder={"Additional Details"}
                                    />
                                </div>

                                <div className="mt-4 flex justify-end gap-2">
                                    <Button
                                        onClick={() => submitDetails()}
                                        disabled={saving}
                                        name={saving ? 'Updating...' : 'Update'}
                                    />
                                    <Button
                                        onClick={closeModal}
                                        name={"Cancel"}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const Button = ({onClick, disabled, name}) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name==="Cancel" ? " bg-white hover:bg-white/90 text-theme-1":" bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}
