const SelectInput = ({ label, options, defaultValue, onChange }) => {
    return (
        <div>
            {label && <label className={"text-white text-[14px] font-medium"}>{label}</label>}
            <select
                id="countries"
                value={defaultValue} // Set the selected value based on the defaultValue prop
                onChange={onChange}
                className="bg-theme-5 mt-1 border-0 text-white text-[14px] focus:ring-0 focus-visible:ring-0
                focus-visible:border-0 focus:border-0 block w-full p-2.5 rounded-md
                border-transparent focus:border-transparent focus:outline-none">
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.svg}{option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectInput;
