import React, {useContext} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {RxDashboard, RxGear} from "react-icons/rx";
import {IoCloudOutline} from 'react-icons/io5';
import MenuDropdown from "./components/menuDropdown";
import {UploaderContext} from '../../../context/uploader.context';
import Logo from '../../../assets/images/logo.svg';
import {MdOutlineCameraAlt} from 'react-icons/md';
import {GoArrowUpLeft} from "react-icons/go";


const sidebarMenu = [
    {
        name: "Dashboard",
        path: "/",
        icon: <RxDashboard/>,
        active: true
    },
    {
        name: "CreatorCloud",
        path: "/creators",
        icon: <IoCloudOutline/>,
        active: false,
        subMenu: [
            {
                name: "My Drive",
                path: "/cloud/drive",
            },
            {
                name: "Shared with me",
                path: "/cloud/share",
            },
            {
                name: "Archived",
                path: "/cloud/archived",
            },
            {
                name: "Earnings",
                path: "/cloud/earnings",
            },
            {
                name: "Contracts (Soon)",
                path: "/cloud/contracts",
            },
            {
                name: "Upgrade",
                path: "/cloud/upgrade",
            },
        ]
    },
    {
        name: "CreatorStock",
        icon: <MdOutlineCameraAlt/>,
        active: false,
        subMenu: [
            {
                name: "My Content",
                path: "/stock/content-manager",
            },
            {
                name: "Upload Queue",
                path: "/stock/upload"
            },
            {
                name: "Collections",
                path: "/stock/collections",
            },
            {
                name: "Purchased",
                path: "/stock/purchase",
            },
            {
                name: "Earnings",
                path: "/stock/earnings",
            },
        ]
    },
    {
        name: "Settings",
        icon: <RxGear/>,
        active: false,
        subMenu: [
            {
                name: "Profile",
                path: "/settings/profile",
            },
            {
                name: "Watermark",
                path: "/settings/watermark"
            },
            {
                name: "Change Password",
                path: "/settings/change-password"
            },
            {
                name: "Logout",
                path: "logout",
            },
        ]
    },
    {
        name: "Go to CreatorStock",
        path: "https://creatorstock.io/",
        icon: <GoArrowUpLeft/>,
        active: true
    },
]

const Sidebar = () => {
    const location = useLocation();
    const {storageData} = useContext(UploaderContext);

    return (
        <aside className={`hidden md:flex h-screen w-[280px] flex-col overflow-y-hidden bg-theme-1 font-normal`}>
            <div className="h-20 flex justify-center items-center gap-2">
                <NavLink to="/" className={"text-white font-bold text-lg flex flex-row gap-2"}>
                    <img
                        className={"w-10"}
                        src={Logo}
                        alt={"svg"}
                    />
                    CreatorCloud
                </NavLink>
            </div>

            <div className={"flex flex-col"}>
                <div className="flex flex-col gap-2 h-[calc(100vh-138px)] overflow-y-auto px-4">
                    {sidebarMenu.map((item, index) => {
                        return (
                            item.subMenu ?
                                <MenuDropdown
                                    name={item.name}
                                    icon={item.icon}
                                    subMenu={item.subMenu}
                                    path={item.path}
                                    location={location}
                                    active={item.active}
                                />
                                :
                                <span
                                    key={index}
                                    className={
                                        `${item.active === true && "bg-black"},
                                             ${location.pathname === item.path ? 'bg-theme-2' : ''}
                                            rounded-md`
                                    }
                                >
                                    <NavLink
                                        to={item.path}
                                        className={
                                            `group relative flex items-center gap-2 rounded-sm p-0 
                                                 duration-300 ease-in-out text-[14px] text-white h-10 px-2
                                                 ${location.pathname === item.path ? 'text-theme-peach-100' : ''}`
                                        }
                                    >
                                        <span className={"text-2xl"}>{item.icon}</span>
                                        {item.name}
                                    </NavLink>
                                </span>
                        )
                    })}
                </div>
                <div
                    className={"flex flex-0 text-white justify-center items-center border-t border-border py-2"}>
                    <div
                        className="h-10 flex items-center text-[12px] rounded font-normal leading px-4 bg-theme-3 ">
                        {storageData?.formatted || 0} used
                    </div>
                </div>
            </div>

        </aside>
    );
};

export default Sidebar;
