import Axios from "../configs/axios.config";
export const uploadProfileImage = (formData) =>
  Axios.post(`/user/update-image`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const uploadWatermarkImage = (formData) =>
  Axios.post(`/user/update-watermark`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const getUserProfile = (id) => Axios.get(`/user/${id}`);
export const updateUserProfileData = (id, data) =>
  Axios.put(`/users/${id}`, data);

export const userTransactions = (params) => Axios.get(`/transactions`, { params });
export const checkBankDetails = () => Axios.get(`/bank/check`);
export const getBankDetails = () => Axios.get(`/bank/get`);
export const getUserBalance = () => Axios.get(`/balance`);
export const requestPayout = (data) => Axios.post(`/withdraw`, data);
export const updateBankDetails = (data) => Axios.post(`/bank/update`, data);
export const getUsersList = (term) => Axios.get(`/find/users?term=${term}`);
export const makePurchase = (uuid, data) => Axios.post(`/folder/checkout/${uuid}`, data);
export const getStorage = () => Axios.get(`/user-storage`);
export const getCurrentUser = () => Axios.get(`/current/user`);
export const categories = () => Axios.get(`/categories`);
export const getPackages = () => Axios.get(`/packages`);
export const createPackagePurchaseSession = (data) => Axios.post(`/packages/purchase`,data);
export const cancelSubscriptionPlan = (data) => Axios.post(`/packages/cancel`,data);
export const keepSubscriptionPlan = (data) => Axios.post(`/packages/keep`,data);
export const activateAddonPlan = (data) => Axios.post(`/packages/addon/activate`,data);
export const cancelAddonPlan = (data) => Axios.post(`/packages/addon/cancel`,data);
export const keepAddonPlan = (data) => Axios.post(`/packages/addon/keep`,data);
export const dashboardData = () => Axios.get(`/dashboard/cloud`);


export const updatePassword = (data) => Axios.put(`/update-password`,data);



/* Login */
export const signIn = (data) => Axios.post(`/login`, data);
export const signUp = (data) => Axios.post(`/register`, data);
