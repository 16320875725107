import { IoCloudUploadOutline } from "react-icons/io5";

const UploadFileInput = ({ label, type, info = "", placeholder, bg }) => {
    return (
        <div className={"w-full"}>
            <input
                className={`hidden`}
                type={"file"}
                id={"upload"}
                placeholder={placeholder}
            />
            <span className={"text-gray-200 text-[14px] font-medium"}>{label}</span>
            <label
                htmlFor={"upload"}
                className={"text-gray-200 text-[30px] font-medium bg-red-100"}
            >
                <span className={"border border-blue-100 border-dashed w-full h-[150px] " +
                    " flex flex-col justify-center items-center rounded-md"}>
                    <IoCloudUploadOutline />

                    <h3 className={"text-[20px] font-medium"}>Drag & Drop</h3>
                    <p className={"text-[14px]"}>Your files here & Browse to upload</p>
                    <p className={"text-[10px] text-indigo-400"}>
                        {info ? info :
                            " Only JPEG, PNG, GIF and PDF files with max size of 100 MB."}
                    </p>
                </span>
            </label>
        </div>
    )
}
export default UploadFileInput;
