import React from 'react'
import { CiSearch } from "react-icons/ci";

const Search = ({ searchText, folderName, handleSearchText, placeholder }) => {
    return (
        <div className='flex justify-start px-[20px] py-[20px] border-b border-black/50'>
            <button className="search-submit btn btn-icon">
                <CiSearch className='text-white' />
            </button>
            <input
                type="text"
                className="form-control border-transparent focus:outline-[0] text-white form-focus-none bg-theme-2 text-[14px] px-1 w-full mx-3"
                placeholder={placeholder || "Search"}
                value={searchText}
                autoFocus={folderName === "Search Files" ? true : false}
                onChange={(ev) => handleSearchText(ev.target?.value)}
            />
        </div>
    )
}

export default Search