import {IoFileTrayOutline, IoFolderOpen} from "react-icons/io5";
import {FaFileAlt} from "react-icons/fa";

const FileCard=({name,file})=>{
    return(
        <div className={"bg-white/10 p-4 flex flex-col rounded-[5px]"}>
            <span className={"text-[14px] text-white flex items-center gap-2 mb-3"}>
                <FaFileAlt className={"text-[20px] text-white"}/>
                {name}
            </span>
            <img src={file} className={"w-full h-[200px] object-cover rounded-[5px]"}/>
        </div>
    )
}
export default FileCard;
