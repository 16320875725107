const SwitchInput = ({ label, onChange, name, checked }) => {
    const handleCheckboxChange = (event) => {
        onChange(name, event.target.checked)
    };
    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input checked={checked} type="checkbox" onChange={handleCheckboxChange} className="sr-only peer" />
            <div className="w-11 h-6 bg-white/10 rounded-full peer peer-focus:ring-4 peer-focus:ring-neutral
            dark:peer-focus:ring-neutral dark:bg-neutral peer-checked:after:translate-x-full
            rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
            after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border
            after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700">
            </div>
            <span className="ms-3 text-sm font-medium text-white dark:text-white">{label}</span>
        </label>
    )
}
export default SwitchInput;
