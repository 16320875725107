import React, {useRef, useState} from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import FolderCard from "./components/FolderCard";
import FileCard from "./components/FileCard";
import File1 from "../../../assets/images/sss.jpg";
import {IoIosArrowForward} from "react-icons/io";
import DropdownButton from "../components/buttons/DropdownButton";
import AddFolderModal from "../components/modals/AddFolderModal";
import AddFileModal from "../components/modals/AddFileModal";
import {IoAdd} from "react-icons/io5";

const Creators = (props) => {
    const [isOpenFolder, setIsOpenFolder] = useState(false)
    const [isOpenFile, setIsOpenFile] = useState(false)
    const modalRef = useRef();

    function closeFolderModal() {
        setIsOpenFolder(false)
    }

    function openFolderModal() {
        setIsOpenFolder(true)
    }

    function closeFileModal() {
        setIsOpenFile(false)
    }

    function openFileModal() {
        setIsOpenFile(true)
    }

    return (
        <div>
            <PageHeading
                heading={"Creator Cloud"}
                profile
            />

            <div className={"p-4"}>
                <div className={"h-14 flex justify-between items-center border-b border-white/5 px-4 mb-4"}>
                    <h2 className={"text-[18px] text-white font-medium flex items-center gap-4"}>
                        <span className={"font-medium"}>My Cloud</span>
                        <span><IoIosArrowForward/></span>
                        <span>New File</span>
                    </h2>
                    <DropdownButton
                        title={"New"}
                        icon={<IoAdd/>}
                        items={[
                            {name: "Add Folder", onClick: openFolderModal},
                            {name: "Add File", onClick: openFileModal},
                        ]}
                        bg
                    />

                    <AddFolderModal
                        isOpen={isOpenFolder}
                        modalRef={modalRef}
                        closeModal={closeFolderModal}
                    />

                    <AddFileModal
                        isOpen={isOpenFile}
                        modalRef={modalRef}
                        closeModal={closeFileModal}
                    />
                </div>
                <div className={"flex flex-col gap-2 mb-5"}>
                    <h2 className={"text-[18px] text-white font-medium"}>Folder</h2>
                    <div className={"grid grid-cols-4 gap-4"}>
                        <FolderCard name={"Vinay's Folder"}/>
                    </div>
                </div>
                <div className={"flex flex-col gap-2"}>
                    <h2 className={"text-[18px] text-white font-medium"}>Files</h2>
                    <div className={"grid grid-cols-4 gap-4"}>
                        <FileCard
                            name={"Vinay's File"}
                            file={File1}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Creators;
