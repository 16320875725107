import {Fragment} from "react";
import {Switch} from "@headlessui/react";
import {IoGridOutline} from "react-icons/io5";
import {LuLayoutList} from "react-icons/lu";

const DefaultSwitch = ({enabled, setEnabled}) => {

    return (
        <Switch checked={enabled} onChange={setEnabled} as={Fragment}>
            {({checked}) => (
                /* Use the `checked` state to conditionally style the button. */
                <button
                    className={`relative flex rounded-md text-[20px] text-white border border-theme-5 overflow-hidden`}
                >
                    <span className={`${checked ? 'bg-theme-3' : 'bg-theme-5'} transform 
                    transition w-10 h-10 flex justify-center items-center `}>
                        <IoGridOutline/>
                    </span>
                    <span className={`${checked ? 'bg-theme-5' : 'bg-theme-3'} transform 
                   transition w-10 h-10 flex justify-center items-center `}>
                        <LuLayoutList/>
                    </span>
                </button>
            )}
        </Switch>
    )
}
export default DefaultSwitch;
