import React from "react";

const ThemeButton = ({ name, onClick, disabled = false }) => {
    return (
        <button
            className={"px-4 h-10 flex items-center text-white text-[14px] font-medium border-0 bg-theme-5 whitespace-nowrap " +
                "rounded-[4px] hover:bg-theme-3 hover:text-white"}
            onClick={onClick}
            disabled={disabled}
        >
            {name}
        </button>
    )
}
export default ThemeButton;
