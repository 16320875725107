import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainRoute from "./views/routes/MainRoute";
import AuthRoute from "./views/routes/AuthRoute";
import Login from "./views/auth/Login";
import Home from "./views/main/home/Home";
import Content from "./views/main/content/Content";
import Upload from "./views/main/upload/Upload";
import Creators from "./views/main/creators/Creators";
import Earnings from "./views/main/earning/earnings";
import Contracts from "./views/main/contracts/contracts";
import Upgrade from "./views/main/upgrade/upgrade";
import Purchase from "./views/main/purchase/purchase";
import Profile from "./views/main/settings/innerPages/profile/profile";
import Watermark from "./views/main/settings/innerPages/watermark/watermark";
import ChangePassword from "./views/main/settings/innerPages/changePassword/changePassword";
import Drive from "./views/main/drive/drive";
import { Toaster } from "react-hot-toast";
import { TOAST_CONFIGS, CustomToaster } from "./utils/toast";
import Shared from './views/shared/shared';
import SingleSignOn from './views/auth/sso/SingleSignOn';
import SharedDrive from './views/main/drive/sharedDrive';
import Collections from './views/main/collections/collections';
import LoginPage from './views/auth/login/page';
import RegisterPage from './views/auth/register/page';
import Archived from './views/main/archived/archived';

const App = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Auth */}
                <Route path="/auth" name="login" element={<AuthRoute />}>
                    {/* <Route path="/auth/login" element={<Login />} /> */}
                    <Route path={"/auth/login"} element={<LoginPage />} />
                    <Route path={"/auth/register"} element={<RegisterPage />} />
                </Route>
                
                <Route path="/" element={<MainRoute />}>

                    {/*Dashboard*/}
                    <Route index element={<Home />} />

                    {/*Stock*/}
                    <Route path={"/stock/content-manager"} element={<Content />} />
                    <Route path={"/stock/upload"} element={<Upload />} />
                    <Route path={"/stock/creators"} element={<Creators />} />
                    <Route path={"/stock/purchase"} element={<Purchase />} />
                    <Route path={"/stock/earnings"} element={<Earnings />} />
                    <Route path={"/stock/collections"} element={<Collections />} />
                    {/*Cloud*/}
                    <Route key="Private-Cloud-Folder" path={"/cloud/drive/:id"} element={<Drive />} />
                    <Route key="Private-Cloud" path={"/cloud/drive"} exact element={<Drive />} />
                    <Route path={"/cloud/upload"} element={<Upload />} />
                    <Route key="Shared-Cloud" path={"/cloud/share"} exact element={<SharedDrive />} />
                    <Route path={"/cloud/earnings"} element={<Earnings />} />
                    <Route path={"/cloud/creators"} element={<Creators />} />
                    <Route path={"/cloud/contracts"} element={<Contracts />} />
                    <Route path={"/cloud/upgrade"} element={<Upgrade />} />
                    <Route path={"/cloud/archived"} element={<Archived />} />

                    {/*Other*/}
                    <Route path={"/settings/profile"} element={<Profile />} />
                    <Route path={"/settings/watermark"} element={<Watermark />} />
                    <Route path={"/settings/change-password"} element={<ChangePassword />} />
                </Route>

                <Route path="/shared/folder/:id" element={<Shared />} />
                <Route path="/shared/folder/:id/:subid" element={<Shared />} />
                <Route path="/sso/:token" element={<SingleSignOn />} />
            </Routes>
            <Toaster {...TOAST_CONFIGS} children={CustomToaster} />
        </BrowserRouter>
    );
}

export default App;
