import React, { useContext, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { MdUpload } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/user.context";
import { FaFolderPlus } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";


export default function AnimationIcons({ openAddFolder, openUploadMOdal,id }) {
    const { user } = useContext(UserContext);
    const [menuOpen, setMenuOpen] = useState(true);
    const [showUplaod, setShowUplaod] = useState("")
    const animation = useRef(null);
    const box1 = useRef(null);
    const box2 = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        animation.current = gsap.timeline().to(box1.current, {
            y: -120,
            x: -5,
            opacity: 1,
            scale: 1,
            backgroundColor: "#000000",
            color: "#ffffff",
        }, "a")
            .to(box2.current, {
                y: -66,
                x: -5,
                opacity: 1,
                scale: 1,
                backgroundColor: "#000000",
                color: "#ffffff",
            }, "a")
            .to(".button", {
                rotate: 405,
                backgroundColor: "#fb2843",
                color: "#ffffff",
            }, "a");

        return () => {
            animation.current.kill();
        };
    }, []);

    const handleClick = () => {
        setShowUplaod(id)
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        if (menuOpen) {
            animation.current.reverse();
        } else {
            animation.current.play();
        }
    }, [menuOpen]);

    return (
        <div className="bottom-[15px] p-0 fixed right-[30px]">
            <div className={`button rounded-[100%] flex h-[60px] relative w-[60px] items-center justify-center text-white bg-theme-peach-100 cursor-pointer z-[99]`} onClick={handleClick}>
                <IoMdAdd className="text-[20px] font-bold" />
            </div>

            <div ref={box2} className="flex items-center rounded-[100%] bottom-[0] h-[50px] justify-center p-0 absolute right-[0] w-[50px] scale-[0.2] text-white bg-black opacity-100 cursor-pointer" onClick={openAddFolder}>
                {/* <FaFolder /> */}
                <FaFolderPlus />
            </div>
            <div ref={box1} className={`${!showUplaod ? "hidden" : "flex"} items-center rounded-[100%] bottom-[0] h-[50px] justify-center p-0 absolute right-[0] w-[50px] box1 scale-[0.2] text-white bg-black opacity-100 cursor-pointer`} onClick={openUploadMOdal}>
                <MdUpload />
            </div>

        </div>
    );
}
