import React from 'react';
import FileListView from '../../main/drive/components/fileListView';
import FileGridView from '../../main/drive/components/fileGridView';

const Files = ({ data, grid, enabled, openViewContent, downloadFile }) => {
    return (
        enabled ?
            <FileListView
                downloadFile={downloadFile}
                openViewContent={openViewContent}
                data={data}
                hide={true}
            />
            :
            <FileGridView
                openViewContent={openViewContent}
                downloadFile={downloadFile}
                data={data}
                hide={true}
                grid={grid}
            />
    );
};


export default Files;
