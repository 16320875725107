import React from 'react';
import FileGridView from "./fileGridView";
import FileListView from "./fileListView";


const Files = ({ data, enabled, openViewContent, archiveFile, deleteFile, restoreFile, downloadFile }) => {
    return (
        enabled ?
            <FileListView
                archiveFile={archiveFile}
                deleteFile={deleteFile}
                restoreFile={restoreFile}
                downloadFile={downloadFile}
                openViewContent={openViewContent}
                data={data}
            />
            :
            <FileGridView
                archiveFile={archiveFile}
                openViewContent={openViewContent}
                deleteFile={deleteFile}
                restoreFile={restoreFile}
                downloadFile={downloadFile}
                data={data}
            />
    );
};


export default Files;
