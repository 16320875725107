import React, { useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import EmptyPage from '../emptyPage/EmptyPage';
import Button from '../buttons/Button'
import { getContentById } from '../../../../service/service.content'
import { SUCCESS200, SUCCESS201 } from '../../../../constants/constants'
import { convertSize } from '../../../../utils/Utils'
import moment from 'moment'
import NoThumb from '../../../../assets/images/no-thumb.jpg'
import { AiOutlineCalendar } from 'react-icons/ai'
import { HiShieldCheck } from 'react-icons/hi'
import VideoPlayer from '../videoPlayer/VideoPlayer'
import { FaFile } from "react-icons/fa";
import Thumb from "../../../../assets/images/no-thumb.jpg"

const ViewContentModal = ({ file = false, isOpen, closeModal, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState([]);
    const videoRef = useRef();

    useEffect(() => {
        if (!id) return
        loadContents(id)
        return () => setContent([])
    }, [id])

    const loadContents = async (id) => {
        setIsLoading(true)
        await getContentById(id)
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200 &&
                    res.data?.content
                ) {
                    const content = res.data?.content;
                    const newContent = {
                        id: content.uuid,
                        meta: {
                            name: file ? content.original_name : content.caption || "No Caption",
                            image: content.thumbnail || NoThumb,
                            type: content.file_type,
                            preview: content.preview,
                            mediumRes: content.mediumRes,
                            time: moment(content.created_at).format("hh:mm A"),
                            date: moment(content.created_at).format("MM/DD/YYYY"),
                            size: content.file_size ? convertSize(content.file_size) : convertSize(0),
                            rejectedReason: content.rejectedReason,
                            approved: content.approved,
                            processing:
                                content.published === 5 || content.isRejected || content.deleted_at
                                    ? "#fa3e3e"
                                    : content.published === 1
                                        ? "#1d2d40"
                                        : "#fc723b",
                            published: content.published,
                            isRejected: content.isRejected,
                            deleted_at: content.deleted_at,
                        }
                    }
                    setContent(newContent)
                    setIsLoading(false)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-[1140px] transform overflow-hidden rounded-2xl bg-theme-1 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white pb-4 p-6"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span className='text-[16px] truncate'>View {file ? "File" : "Content"} <span title={content.meta?.name} className='text-[14px]'> ({content.meta?.name || "Fetching..."})</span></span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                    </div>
                                </Dialog.Title>

                                {isLoading ? (
                                    <div className='flex justify-center items-center text-white mt-4 h-[80vh]'>Loading...</div>
                                ) :
                                    <div className="">
                                        <div className='text-white'>
                                            {content && content.meta ?
                                                <>
                                                    <div className="bg-[#000] flex justify-center p-0 relative w-full">
                                                        <div
                                                            className={"max-h-screen w-full flex h-[80vh] justify-center items-center"}>
                                                            {content.meta?.type === "video" ? (
                                                                <VideoPlayer
                                                                    url={content?.meta?.mediumRes}
                                                                    thumbnail={content?.meta?.image}
                                                                />
                                                            ) : (
                                                                <img
                                                                    onError={(e) => {
                                                                        e.target.src = Thumb;
                                                                    }}
                                                                    src={content?.meta?.mediumRes}
                                                                    className="absolute block !w-full h-full object-contain !max-h-full rounded-[10px]
                                                        "/>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* <div className='flex flex-col p-6 lg:flex-row lg:items-center lg:h-12 px-4 py-4 lg:py-0 bg-theme-5 gap-4 border-b'>
                                                        <div className="flex flex-row text-white text-[12px] lg:text-[14px] gap-2">
                                                            <span className="w-[24px] h-[24px] flex justify-center items-center bg-theme-3 rounded-md">
                                                                <FaFile className="text-[white]" />
                                                            </span>
                                                            Name : {content.meta?.name}
                                                        </div>
                                                    </div> */}
                                                    <div className="flex flex-col p-6 lg:flex-row lg:items-center lg:h-12 px-4 py-4 lg:py-0 bg-theme-5 gap-4">
                                                        <div className="flex flex-row text-white text-[12px] lg:text-[14px] gap-2">
                                                            <span className="w-[24px] h-[24px] flex justify-center items-center bg-theme-3 rounded-md">
                                                                <AiOutlineCalendar className="text-white" />
                                                            </span>
                                                            Published on {moment(content.meta?.date).format("MMMM Do YYYY")}
                                                        </div>
                                                        {/* <div className="flex flex-row text-white text-[12px] lg:text-[14px] gap-2">
                                                            <span className="w-[24px] h-[24px] flex justify-center items-center bg-theme-3 rounded-md">
                                                                <FaFile className="text-[white]" />
                                                            </span>
                                                            Name : {content.meta?.name}
                                                        </div> */}
                                                    </div>
                                                </> : <EmptyPage />}
                                        </div>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ViewContentModal
